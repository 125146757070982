import { graphql, useStaticQuery } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import './Inspiration.scss'

const Inspiration = () => {
  const {
    mdx: {
      body,
    },
  } = useStaticQuery(graphql`
    query InspirationQuery {
      mdx(fields: { title: { eq: "Inspiration" } } ) {
        body
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Inspiration"
      />
      <div id="inspiration-page">
        <MDXRenderer>{body}</MDXRenderer>
      </div>
    </Layout>
  )
}

export default Inspiration